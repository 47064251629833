<div class="section-body mt-3">
  <div class="container-fluid">
    <div class="row clearfix">
      <form class="card" role="form" [formGroup]="payrollAreaForm" novalidate>
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="col-md-12"*ngIf="showHelpDoc">
                <div class="float-right"><span (click)="openPopup(document)">
                        <span class="ml-1 text-link ">User Guide</span>
                    </span>
                </div>
            </div>
              <div class="card-body">

                <div class="row">
                  <div class="col-sm-3">
                    <label class="form-label">Code</label>
                    <input type="text" formControlName="payrollAreaCode" class="form-control" placeholder="Code"
                      maxlength="30" onkeypress="return event.which != 32"
                      [ngClass]="{'is-invalid' : payrollAreaForm.get('payrollAreaCode').status == 'INVALID'}">
                  </div>
                  <div class="col-sm-3">
                    <label class="form-label">Description</label>
                    <input type="text" formControlName="payrollAreaDescription" class="form-control"
                      [ngClass]="{'is-invalid' : payrollAreaForm.get('payrollAreaDescription').status == 'INVALID'}"
                      maxlength="80" placeholder="Description">
                  </div>
                  <div class="col-sm-3">
                    <label class="form-label ml-13" placement="left">
                      Cycle Definition
                    </label>
                    <select formControlName="businessCycleDefinitionId" class="form-control custom-select" (change)="setFrequency($event.target.value)"
                      [ngClass]="{'is-invalid' : payrollAreaForm.get('businessCycleDefinitionId').status == 'INVALID'}">
                      <option value="">Select</option>
                      <option *ngFor="let data of businessCycleData" [value]=data.id>
                        {{data.name}}
                      </option>
                    </select>
                  </div>
                  <div class="col-sm-3">
                    <label class="form-label">Frequency </label>
                    <input type="text" value="{{frequency}}" class="form-control" maxlength="80" placeholder="Frequency" disabled>
                  </div>
                </div>
                <div class="row mt-15">
                  <div class="col-sm-3">
                    <label class="form-label ml-13" placement="left">
                      PHG
                    </label>
                    <select formControlName="headGroupDefinitionId" class="form-control custom-select"
                      [ngClass]="{'is-invalid' : payrollAreaForm.get('headGroupDefinitionId').status == 'INVALID'}">
                      <option value="">Select</option>
                      <option *ngFor="let data of phgData" [value]=data.headGroupDefinitionId>
                        {{data.headGroupDefinitionName}}
                      </option>
                    </select>
                  </div>
                  <div class="col-sm-3">
                    <label class="form-label">Currency</label>
                    <select formControlName="currency" class="form-control custom-select"
                      [ngClass]="{'is-invalid' : payrollAreaForm.get('currency').status == 'INVALID'}">
                      <option value="">Select</option>
                      <option *ngFor="let data of currancyData" [value]=data>
                        {{data}}
                      </option>
                    </select>
                  </div>

                  <div class="col-sm-3">
                    <label class="form-label ml-15" placement="left">
                      Default Attendance
                    </label>
                    <div class="custom-controls-stacked ml-15 mt-10">
                      <label class="custom-control custom-radio custom-control-inline">
                        <input formControlName="defaultAttendace" type="radio" class="custom-control-input" [value]="0"
                          [checked]="defaultAttendace == 0">
                        <span class="custom-control-label">0</span>
                      </label>
                      <label class="custom-control custom-radio custom-control-inline">
                        <input formControlName="defaultAttendace" type="radio" class="custom-control-input" [value]="1"
                          [checked]="defaultAttendace == 1">
                        <span class="custom-control-label">1</span>
                      </label>
                    </div>
                  </div>
                  <div class="col-sm-3">
                    <label class="form-label">Standard Minutes per Day </label>
                    <input type="text"  onkeypress="return /[0-9.]/i.test(event.key)" class="form-control" maxlength="80" placeholder="Standard Minutes per Day"
                    formControlName="standardminperDays"
                    [ngClass]="{'is-invalid' : payrollAreaForm.get('standardminperDays').status == 'INVALID'}">
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col-sm-3">
                    <label class="form-label ml-15" placement="left">
                      Is 30 Days Denominator
                    </label>
                    <div class="custom-controls-stacked ml-15 mt-10">
                      <div class="custom-controls-stacked ml-15 mt-10">
                        <label class="custom-control custom-radio custom-control-inline">
                          <input formControlName="isFixedDenominator" type="radio" class="custom-control-input" [value]="0"
                            [checked]="is30daysPayroll == false">
                          <span class="custom-control-label">No</span>
                        </label>
                        <label class="custom-control custom-radio custom-control-inline">
                          <input formControlName="isFixedDenominator" type="radio" class="custom-control-input" [value]="1"
                            [checked]="is30daysPayroll == true">
                          <span class="custom-control-label">Yes</span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3" *ngIf="is30daysPayroll">
                    <label class="form-label">Adjustment </label>
                    <select name="select" class="form-control custom-select">
                      <option value="">Select </option>
                      <option> Payable Days Above 50% of Total Days</option>
                      <option>Irrespective of Payable Days</option>
                    </select>
                  </div>
                  <div class="col-sm-3">
                    <label class="form-label ml-13" placement="left">
                      Default Language
                    </label>
                    <select formControlName="defaultLanguage" class="form-control custom-select"
                      [ngClass]="{'is-invalid' : payrollAreaForm.get('defaultLanguage').status == 'INVALID'}">
                      <option value="">Select</option>
                      <option *ngFor="let data of languageData" [value]=data>
                        {{data}}
                      </option>
                    </select>
                  </div>
                  <div class="col-sm-3">
                    <label class="form-label ml-13" placement="left">
                      Min. Net Pay - SDM Name
                    </label>
                    <select formControlName="minNetPaySDM" class="form-control custom-select"
                      (change)="getAllDerivedSDM($event.target.value)"
                      [ngClass]="{'is-invalid' : payrollAreaForm.get('minNetPaySDM').status == 'INVALID'}">
                      <option value="">Select</option>
                      <option *ngFor="let data of sdmData" [value]=data.sdmName>
                        {{data.sdmName}}
                      </option>
                    </select>
                  </div>
                  <div class="col-sm-3">
                    <label class="form-label ml-13" placement="left">
                      Min. Net Pay - Derived Name
                    </label>
                    <select formControlName="minNetPayDerivedId" class="form-control custom-select" [disabled]="viewFlag"

                    [ngClass]="{'is-invalid' : payrollAreaForm.get('minNetPayDerivedId').status == 'INVALID'}">
                      <option value="">Select</option>
                      <option *ngFor="let data of sdmDerivedList" value="{{data.sdmDerivedMasterId}}">
                        {{ data.derivedName }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="row mt-3">

                  <div class="col-sm-3">
                    <label class="form-label ml-13" placement="left">
                      Company
                    </label>
                    <select formControlName="arraylistCompanyIds" class="form-control custom-select"
                      [ngClass]="{'is-invalid' : payrollAreaForm.get('arraylistCompanyIds').status == 'INVALID'}">
                      <option value="">Select</option>
                      <option *ngFor="let data of companyData" [value]=data.groupCompanyId>
                        {{data.companyName}}
                      </option>
                    </select>
                  </div>
                  <div class="col-sm-3">
                    <label class="form-label ml-13" placement="left">
                      LMS Area Master
                    </label>
                    <select formControlName="lmsAreaId" class="form-control custom-select"
                      [ngClass]="{'is-invalid' : payrollAreaForm.get('lmsAreaId').status == 'INVALID'}">
                      <option value="">Select</option>
                      <option *ngFor="let data of lmsAreaData" [value]=data.lmsAreaMasterId>
                        {{data.code}}
                      </option>
                    </select>
                  </div>
                  <div class="col-md-3">
                    <label class="form-label">Expense Area Master </label>
                    <select name="select" class="form-control custom-select" formControlName="expenseReimbursementAreaMasterId"
                    [ngClass]="{'is-invalid' : payrollAreaForm.get('expenseReimbursementAreaMasterId').status == 'INVALID'}">
                      <option value="">Select </option>
                      <option value="{{data.expensereimbursementAreaMasterId}}" *ngFor="let data of expenseReimbursementAreaData">
                        {{data.areaMastercode}}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col-sm-3">
                    <label class="form-label ml-13" placement="left">
                      UOM
                    </label>
                    <select class="form-control custom-select" formControlName="uom"
                      [ngClass]="{'is-invalid' : payrollAreaForm.get('uom').status == 'INVALID'}">
                      <option value="">Select</option>
                      <option value="MM"> Month</option>
                      <option value="Quarterly"> Quarter</option>
                      <option value="HalfYearly"> HalfYear</option>
                      <option value="Yearly"> Year</option>
                      <option value="SemiMonthly"> SemiMonth </option>
                      <option value="Weekly"> Week </option>
                      <option value="BiWeekly"> BiWeek </option>
                    </select>
                  </div>

                  <div class="col-md-3">
                    <label class="form-label">JV Group </label>
                    <select name="select" class="form-control custom-select" formControlName="jvGroupName"
                    [ngClass]="{'is-invalid' : payrollAreaForm.get('jvGroupName').status == 'INVALID'}">
                      <option value="">Select </option>
                      <option *ngFor="let data of jvGroupData">
                        {{data.jvGroupName}}
                      </option>
                    </select>
                  </div>

                  <div class="col-md-3">
                    <label class="form-label">Payment Tracking Master Group </label>
                    <select name="select" class="form-control custom-select" formControlName="paymentTrackingGroupName"
                    [ngClass]="{'is-invalid' : payrollAreaForm.get('paymentTrackingGroupName').status == 'INVALID'}">
                      <option value="">Select </option>
                      <option *ngFor="let data of paymentTrackingGroupData">{{data.code}} </option>

                    </select>
                  </div>
                  <div class="col-md-3">
                    <label class="form-label">Income Tax Setting Template </label>
                    <select name="select" class="form-control custom-select" formControlName="incomeTaxSettingTemplateName"
                    [ngClass]="{'is-invalid' : payrollAreaForm.get('incomeTaxSettingTemplateName').status == 'INVALID'}">
                      <option value="">Select </option>
                      <option *ngFor="let taxtemplate of incometaxTemplateData">{{taxtemplate.templateName}} </option>
                    </select>
                  </div>
                </div>

                <div class="row mt-3">

                  <div class="col-sm-3">
                    <label class="form-label">Enable Payslip From</label>
                    <div class="form-group">
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <span class="input-group-text"><i class="fa fa-calendar"></i></span>
                        </div>
                        <input [bsConfig]="{ dateInputFormat: 'DD-MMM-YYYY' }" data-provide="datepicker"
                          [ngClass]="{'is-invalid' : payrollAreaForm.get('payslipShowFrom').status == 'INVALID'}"
                          data-date-autoclose="true" class="form-control" (bsValueChange)="setPayslipDate($event)"
                          [minDate]="selectedFromDate"
                          [disabled]="viewFlag" placeholder="Enable Payslip From Date" bsDatepicker
                          [ngClass]="{'is-invalid' : payrollAreaForm.get('payslipShowFrom').status == 'INVALID'}" [bsValue]="selectedPayslipDate">
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-3">
                    <label class="form-label">Enable Taxsheet From</label>
                    <div class="form-group">
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <span class="input-group-text"><i class="fa fa-calendar"></i></span>
                        </div>
                        <input [bsConfig]="{ dateInputFormat: 'DD-MMM-YYYY' }" data-provide="datepicker"

                        [ngClass]="{'is-invalid' : payrollAreaForm.get('taxsheetShowFrom').status == 'INVALID'}"
                          data-date-autoclose="true" class="form-control" (bsValueChange)="setTaxsheetDate($event)"
                          placeholder="Enable Taxsheet From Date" bsDatepicker [bsValue]="selectedTaxsheetDate"
                          [disabled]="viewFlag"

                          >
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-3">
                    <label class="form-label">From Date</label>
                    <div class="form-group">
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <span class="input-group-text"><i class="fa fa-calendar"></i></span>
                        </div>
                        <input [bsConfig]="{ dateInputFormat: 'DD-MMM-YYYY' }" data-provide="datepicker"
                          data-date-autoclose="true" class="form-control" (bsValueChange)="setFromDate($event)"
                          [disabled]="viewFlag" placeholder="From Date" bsDatepicker
                          [bsValue]="selectedFromDate"
                          [ngClass]="{'is-invalid' : payrollAreaForm.get('effectiveFromDate').status == 'INVALID'}">
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-3">
                    <label class="form-label">To Date</label>
                    <div class="form-group">
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <span class="input-group-text"><i class="fa fa-calendar"></i></span>
                        </div>
                        <input [bsConfig]="{ dateInputFormat: 'DD-MMM-YYYY' }" data-provide="datepicker"
                        data-date-autoclose="true" class="form-control" (bsValueChange)="setToDate($event)"
                          placeholder="To Date" bsDatepicker [bsValue]="selectedToDate"
                          [disabled]="viewFlag"
                          [ngClass]="{'is-invalid' : payrollAreaForm.get('effectiveToDate').status == 'INVALID'}"
                          [minDate]="selectedFromDate">
                      </div>
                    </div>
                  </div>


                </div>

                <div class="row mt-3">
                  <div class="col-sm-3">
                    <label class="custom-control custom-checkbox">
                      <input type="checkbox" class="custom-control-input" (click)="checkedUnchecked($event.target)"
                        [disabled]="viewFlag" name="example-checkbox4" value="option4" [checked]="!isShowRemark">
                      <span class="custom-control-label">Active</span>
                    </label>
                  </div>
                  <div class="col-sm-3" *ngIf="isShowRemark">
                    <label class="form-label">Remark</label>
                    <textarea formControlName="remark" class="form-control"
                      [ngClass]="{'is-invalid' : payrollAreaForm.get('remark').status == 'INVALID'}"></textarea>
                  </div>
                </div>
                <div class="row mt-15">
                  <div class="col-12">
                    <div class="float-right mb-15">
                      <button *ngIf="!editFlag && !viewFlag" (click)="submit()" tooltip="Save"
                        [disabled]="payrollAreaForm.invalid ||isSaveEnable"
                        [ngClass]="{'disabled-btn-bg-green' : payrollAreaForm.invalid ||isSaveEnable}"
                        class="custom_button custom_btn btn-bg-green btn-labeled btn-labeled-left ripple">
                        <b><i class="fa fa-save"></i></b>
                        <span> Save</span>
                      </button>
                      <button *ngIf="editFlag && !viewFlag" (click)="update()" tooltip="Update"
                      [disabled]="payrollAreaForm.invalid"
                      [ngClass]="{'disabled-btn-bg-green' : payrollAreaForm.invalid}"
                        class="custom_button custom_btn btn-bg-green btn-labeled btn-labeled-left ripple">
                        <b><i class="fa fa-save"></i></b>
                        <span> Update</span>
                      </button>
                      <button *ngIf="!editFlag && !viewFlag" (click)="resetForm()" tooltip="Reset"
                        class="custom_button custom_btn bg-teal-400 btn-labeled btn-labeled-left ml-10">
                        <b><i class="fa fa-refresh"></i></b> Reset</button> &nbsp;
                      <button *ngIf="editFlag || viewFlag" (click)="resetForm()" tooltip="Close"
                        class="custom_button custom_btn bg-btn-red btn-labeled btn-labeled-left ml-10">
                        <b><i class="fa fa-close"></i></b> Close</button> &nbsp;
                    </div>
                  </div>
                </div>
                <div class="row mt-25">
                  <div class="table-responsive Custom_Table">
                    <p-table #dt1 [paginator]="true" [rows]="10" [showCurrentPageReport]="true"
                      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                      [rowsPerPageOptions]="[10, 25, 50, 100, 200]" [value]="summary" [globalFilterFields]="['payrollAreaCode','payrollAreaDescription','headGroupDefinitionResponse','businessCycleDefinitionName',
                      'defaultAttendace','uom','companyName',
                      'headGroupDefinitionResponse.headGroupDefinitionName','effectiveFromDate','effectiveToDate','isActiveT']">
                      <ng-template pTemplate="caption">
                        <div class="row">
                          <div class="col-md-12">
                            <div class="float-right">
                              <div class="d-flex">
                                <span class="p-input-icon-left p-ml-auto">
                                  <i class="pi pi-search search-css"></i>
                                  <input pInputText type="text"
                                    (input)="dt1.filterGlobal($event.target.value, 'contains')" placeholder="Search " />
                                </span>
                                <span>
                                  <a tooltip="Export to Excel" (click)="exportAsXLSX(summary,'Payroll Area Summary')">
                                    <i class="fa fa-file-excel-o excel-icon-css" aria-hidden="true"></i>
                                  </a>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </ng-template>
                      <ng-template pTemplate="header">
                        <tr>
                          <th>
                            <div class="p-d-flex justify-content-center p-ai-center">
                              Sr. No.
                            </div>
                          </th>
                          <th>
                            <div class="p-d-flex justify-content-center p-ai-center">
                              Code
                              <div class="Sort_Filter_Position">
                                <p-sortIcon field="payrollAreaCode" pSortableColumn="payrollAreaCode">
                                </p-sortIcon>
                                <p-columnFilter type="text" field="payrollAreaCode" display="menu" class="p-ml-auto">
                                </p-columnFilter>
                              </div>
                            </div>
                          </th>
                          <th>
                            <div class="p-d-flex justify-content-center p-ai-center">
                              Description
                              <div class="Sort_Filter_Position">
                                <p-sortIcon field="payrollAreaDescription" pSortableColumn="payrollAreaDescription">
                                </p-sortIcon>
                                <p-columnFilter type="text" field="payrollAreaDescription" display="menu"
                                  class="p-ml-auto">
                                </p-columnFilter>
                              </div>
                            </div>
                          </th>
                          <th>
                            <div class="p-d-flex justify-content-center p-ai-center">
                              Cycle Definition
                              <div class="Sort_Filter_Position">
                                <p-sortIcon field="businessCycleDefinitionName"
                                  pSortableColumn="businessCycleDefinitionName">
                                </p-sortIcon>
                                <p-columnFilter type="text" field="businessCycleDefinitionName" display="menu"
                                  class="p-ml-auto">
                                </p-columnFilter>
                              </div>
                            </div>
                          </th>
                          <th>
                            <div class="p-d-flex justify-content-center p-ai-center">
                              PHG
                              <div class="Sort_Filter_Position">
                                <p-sortIcon field="headGroupDefinitionResponse.headGroupDefinitionName"
                                  pSortableColumn="headGroupDefinitionResponse.headGroupDefinitionName">
                                </p-sortIcon>
                                <p-columnFilter type="text" field="headGroupDefinitionResponse.headGroupDefinitionName"
                                  display="menu" class="p-ml-auto">
                                </p-columnFilter>
                              </div>
                            </div>
                          </th>
                          <th rowspan="2" class="wd-8 text-center">
                            <div class="p-d-flex p-jc-between p-ai-center">
                              Default Attendance

                              <div class="Sort_Filter_Position">
                                <p-sortIcon
                                  field="defaultAttendace"
                                  pSortableColumn="defaultAttendace"
                                ></p-sortIcon>
                                <p-columnFilter
                                type="numeric"
                                [minFractionDigits]="0"

                                field="outcomePriceYes"
                                  field="defaultAttendace"
                                  display="menu"
                                  class="p-ml-auto"
                                >
                                </p-columnFilter>
                              </div>
                            </div>
                          </th>

                          <th>
                            <div class="p-d-flex justify-content-center p-ai-center">
                              UOM
                              <div class="Sort_Filter_Position">
                                <p-sortIcon field="uom" pSortableColumn="uom">
                                </p-sortIcon>
                                <p-columnFilter type="text" field="uom" display="menu" class="p-ml-auto">
                                </p-columnFilter>
                              </div>
                            </div>
                          </th>
                          <th>
                            <div class="p-d-flex justify-content-center p-ai-center">
                              Company Name
                              <div class="Sort_Filter_Position">
                                <p-sortIcon field="companyName" pSortableColumn="companyName">
                                </p-sortIcon>
                                <p-columnFilter type="text" field="companyName" display="menu" class="p-ml-auto">
                                </p-columnFilter>
                              </div>
                            </div>
                          </th>
                          <th rowspan="2">
                            <div class="p-d-flex justify-content-center p-ai-center">
                              From Date
                              <div class="Sort_Filter_Position">
                                <p-sortIcon field="effectiveFromDate" pSortableColumn="effectiveFromDate">
                                </p-sortIcon>
                                <p-columnFilter type="date" field="effectiveFromDate" display="menu" class="p-ml-auto">
                                </p-columnFilter>
                              </div>
                            </div>
                          </th>
                          <th>
                            <div class="p-d-flex justify-content-center p-ai-center">
                              To Date
                              <div class="Sort_Filter_Position">
                                <p-sortIcon field="effectiveToDate" pSortableColumn="effectiveToDate">
                                </p-sortIcon>
                                <p-columnFilter type="date" field="effectiveToDate" display="menu" class="p-ml-auto">
                                </p-columnFilter>
                              </div>
                            </div>
                          </th>
                          <th class="text-center" rowspan="2">Active</th>
                          <th class="text-center" rowspan="2">Action</th>
                        </tr>
                      </ng-template>
                      <ng-template pTemplate="body" let-summary let let-rowIndex="rowIndex">
                        <tr class="p-selectable-row">
                          <td class="text-left"> {{rowIndex+1}} </td>
                          <td class="text-left"> {{ summary.payrollAreaCode }} </td>
                          <td class="text-left"> {{summary.payrollAreaDescription}} </td>
                          <td class="text-left"> {{summary.businessCycleDefinitionName}} </td>
                          <td class="text-left"> {{summary.headGroupDefinitionResponse.headGroupDefinitionName}} </td>
                          <td class="text-center"> {{summary.defaultAttendace}} </td>
                          <td class="text-left"> {{summary.uom}} </td>
                          <td class="text-left"> {{summary.companyName}} </td>
                          <td class="text-center"> {{ summary.effectiveFromDate | date :'dd-MMM-yyyy' }} </td>
                          <td class="text-center"> {{ summary.effectiveToDate | date :'dd-MMM-yyyy' }} </td>
                          <td class="text-center">
                            <span *ngIf="summary.isActive == 1">Yes</span>
                            <span *ngIf="summary.isActive == 0">No</span>
                          </td>
                          <td class="text-center">
                            <span>
                              <i class="fa fa-edit edit-icon-css mr-2" (click)="editPayroll(summary)"
                                tooltip="Edit"></i>
                              <i class="fa fa-eye eye-icon-css mr-2" (click)="viewPayroll(summary)" tooltip="View"></i>
                              <i class="fas fa-history text-purple cursor-pointer"
                                                                            tooltip="History"
                                                                        (click)="smallpopup(payrollHistory,summary.payrollAreaCode)"></i>
                            </span>
                          </td>
                        </tr>
                      </ng-template>
                    </p-table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
<ng-template #document>
  <app-help-document-popup [modalRef1]="modalRef" [type]="type"></app-help-document-popup>
</ng-template>

<ng-template #payrollHistory>
  <div class="modal-header">
    <h5 class="modal-title" id="UploadModalLabel"> Payroll Area History
    </h5>
    <button type="button" (click)="modalRef.hide()" class="close" data-dismiss="modal" tooltip="Close"
        aria-label="Close"><span aria-hidden="true">×</span></button>
</div>
<div class="modal-body">
  <div class="card">
      <div class="card-body">
          <div class="row clearfix">
              <div class="col-md-12">
                <div class="row mt-25">
                  <div class="table-responsive Custom_Table">
                    <p-table #dt1 [paginator]="true" [rows]="10" [showCurrentPageReport]="true"
                      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                      [rowsPerPageOptions]="[10, 25, 50, 100, 200]" [value]="summaryHistory" [globalFilterFields]="['payrollAreaCode','payrollAreaDescription','headGroupDefinitionResponse','businessCycleDefinitionName',
                      'defaultAttendace','uom','companyName',
                      'headGroupDefinitionResponse.headGroupDefinitionName','effectiveFromDate','effectiveToDate','isActiveT']">
                      <ng-template pTemplate="caption">
                        <div class="row">
                          <div class="col-md-12">
                            <div class="float-right">
                              <div class="d-flex">
                                <span class="p-input-icon-left p-ml-auto">
                                  <i class="pi pi-search search-css"></i>
                                  <input pInputText type="text"
                                    (input)="dt1.filterGlobal($event.target.value, 'contains')" placeholder="Search " />
                                </span>
                                <span>
                                  <a tooltip="Export to Excel" (click)="exportAsXLSX(summaryHistory,'Payroll Area History')">
                                    <i class="fa fa-file-excel-o excel-icon-css" aria-hidden="true"></i>
                                  </a>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </ng-template>
                      <ng-template pTemplate="header">
                        <tr>
                          <th>
                            <div class="p-d-flex justify-content-center p-ai-center">
                              Sr No.
                            </div>
                          </th>
                          <th>
                            <div class="p-d-flex justify-content-center p-ai-center">
                              Code
                              <div class="Sort_Filter_Position">
                                <p-sortIcon field="payrollAreaCode" pSortableColumn="payrollAreaCode">
                                </p-sortIcon>
                                <p-columnFilter type="text" field="payrollAreaCode" display="menu" class="p-ml-auto">
                                </p-columnFilter>
                              </div>
                            </div>
                          </th>
                          <th>
                            <div class="p-d-flex justify-content-center p-ai-center">
                              Description
                              <div class="Sort_Filter_Position">
                                <p-sortIcon field="payrollAreaDescription" pSortableColumn="payrollAreaDescription">
                                </p-sortIcon>
                                <p-columnFilter type="text" field="payrollAreaDescription" display="menu"
                                  class="p-ml-auto">
                                </p-columnFilter>
                              </div>
                            </div>
                          </th>
                          <th>
                            <div class="p-d-flex justify-content-center p-ai-center">
                              Cycle Definition
                              <div class="Sort_Filter_Position">
                                <p-sortIcon field="businessCycleDefinitionName"
                                  pSortableColumn="businessCycleDefinitionName">
                                </p-sortIcon>
                                <p-columnFilter type="text" field="businessCycleDefinitionName" display="menu"
                                  class="p-ml-auto">
                                </p-columnFilter>
                              </div>
                            </div>
                          </th>
                          <th>
                            <div class="p-d-flex justify-content-center p-ai-center">
                              PHG
                              <div class="Sort_Filter_Position">
                                <p-sortIcon field="headGroupDefinitionResponse.headGroupDefinitionName"
                                  pSortableColumn="headGroupDefinitionResponse.headGroupDefinitionName">
                                </p-sortIcon>
                                <p-columnFilter type="text" field="headGroupDefinitionResponse.headGroupDefinitionName"
                                  display="menu" class="p-ml-auto">
                                </p-columnFilter>
                              </div>
                            </div>
                          </th>

                          <th rowspan="2" class="wd-8 text-center">
                            <div class="p-d-flex p-jc-between p-ai-center">

                              Default Attendance
                              <div class="Sort_Filter_Position">
                                <p-sortIcon
                                  field="defaultAttendace"
                                  pSortableColumn="defaultAttendace"
                                ></p-sortIcon>
                                <p-columnFilter
                                type="numeric"
                                [minFractionDigits]="0"
                                field="outcomePriceYes"
                                  field="defaultAttendace"
                                  display="menu"
                                  class="p-ml-auto"
                                >
                                </p-columnFilter>
                              </div>
                            </div>
                          </th>
                          <th>
                            <div class="p-d-flex justify-content-center p-ai-center">
                              UOM
                              <div class="Sort_Filter_Position">
                                <p-sortIcon field="uom" pSortableColumn="uom">
                                </p-sortIcon>
                                <p-columnFilter type="text" field="uom" display="menu" class="p-ml-auto">
                                </p-columnFilter>
                              </div>
                            </div>
                          </th>
                          <th>
                            <div class="p-d-flex justify-content-center p-ai-center">
                              Company Name
                              <div class="Sort_Filter_Position">
                                <p-sortIcon field="companyName" pSortableColumn="companyName">
                                </p-sortIcon>
                                <p-columnFilter type="text" field="companyName" display="menu" class="p-ml-auto">
                                </p-columnFilter>
                              </div>
                            </div>
                          </th>
                          <th rowspan="2">
                            <div class="p-d-flex justify-content-center p-ai-center">
                              From Date
                              <div class="Sort_Filter_Position">
                                <p-sortIcon field="effectiveFromDate" pSortableColumn="effectiveFromDate">
                                </p-sortIcon>
                                <p-columnFilter type="date" field="effectiveFromDate" display="menu" class="p-ml-auto">
                                </p-columnFilter>
                              </div>
                            </div>
                          </th>
                          <th>
                            <div class="p-d-flex justify-content-center p-ai-center">
                              To Date
                              <div class="Sort_Filter_Position">
                                <p-sortIcon field="effectiveToDate" pSortableColumn="effectiveToDate">
                                </p-sortIcon>
                                <p-columnFilter type="date" field="effectiveToDate" display="menu" class="p-ml-auto">
                                </p-columnFilter>
                              </div>
                            </div>
                          </th>
                          <th class="text-center" rowspan="2">Active</th>
                          <th class="text-center" rowspan="2">Action</th>
                        </tr>
                      </ng-template>
                      <ng-template pTemplate="body" let-summary let let-rowIndex="rowIndex">
                        <tr class="p-selectable-row">
                          <td class="text-left"> {{rowIndex+1}} </td>
                          <td class="text-left"> {{ summary.payrollAreaCode }} </td>
                          <td class="text-left"> {{summary.payrollAreaDescription}} </td>
                          <td class="text-left"> {{summary.businessCycleDefinitionName}} </td>
                          <td class="text-left"> {{summary.headGroupDefinitionResponse.headGroupDefinitionName}} </td>
                          <td class="text-center"> {{summary.defaultAttendace}} </td>
                          <td class="text-left"> {{summary.uom}} </td>
                          <td class="text-left"> {{summary.companyName}} </td>
                          <td class="text-center"> {{ summary.effectiveFromDate | date :'dd-MMM-yyyy' }} </td>
                          <td class="text-center"> {{ summary.effectiveToDate | date :'dd-MMM-yyyy' }} </td>
                          <td class="text-center">
                            <span *ngIf="summary.isActive == 1">Yes</span>
                            <span *ngIf="summary.isActive == 0">No</span>
                          </td>
                          <td class="text-center">
                            <span>

                              <i class="fa fa-eye eye-icon-css mr-2" (click)="viewPayroll(summary);this.modalRef.hide();" tooltip="View"></i>

                            </span>
                          </td>
                        </tr>
                      </ng-template>
                    </p-table>
                  </div>
                </div>
              </div>
          </div>
      </div>
  </div>
</div>


</ng-template>
