export const environment = {
  production: false,
  baseUrl8080: '/hrms/v1/',
  baseUrl8081: '/hrms/v4/',
  baseUrl8082: '/hrms/v5/',
  baseUrl8083: '/hrms/v3/',
  baseUrl8084: '/hrms/v2/',
  baseUrl8085: '/hrms/v6/',
  baseUrl8086: '/hrms/v7/',
  baseUrl8087: '/hrms/v8/',
  baseUrl8088: '/hrms/v11/',
  baseUrl8089: '/hrms/v9/',
  baseUrl8090: '/hrms/l7/',
  baseUrl8091: '/hrms/v17/',
  baseUrl8092: '/hrms/v18/',
  baseUrl8093: '/hrms/v10/',
  baseUrl8094: '/hrms/v14/',
  baseUrl8095: '/hrms/v15/',
  baseUrl8096: '/hrms/v12/',
  baseUrl8097: '/hrms/v13/',
  baseUrl8099: '/hrms/v20/',
  baseUrl8100: '/hrms/v19/',
};
 